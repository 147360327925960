import React from "react"

export const monochromeLogo = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.4 2C7.17909 2 7 1.82091 7 1.6C7 0.716344 7.71634 0 8.6 0H9C10.1046 0 11 0.895431 11 2V4.19098C11 4.56267 10.6088 4.80442 10.2764 4.6382L10.1056 4.55279C9.428 4.214 9 3.52148 9 2.76393V2H7.4ZM7.6 10C7.82091 10 8 9.82091 8 9.6C8 8.71634 7.28366 8 6.4 8H6C4.34315 8 3 6.65685 3 5V2.4C3 2.17909 2.82091 2 2.6 2C1.71634 2 1 2.71634 1 3.6V5C1 7.76142 3.23858 10 6 10H7.6Z"
      fill="currentColor"
    />
    <path
      d="M3 11.5C3 11.7761 2.77614 12 2.5 12H2C1.44772 12 1 11.5523 1 11L1 9.80902C1 9.43733 1.39116 9.19558 1.72361 9.3618L1.89443 9.44721C2.572 9.786 3 10.4785 3 11.2361V11.5Z"
      fill="currentColor"
    />
    <path
      d="M9.66667 12C9.29848 12 9 11.7015 9 11.3333V9C9 7.34315 7.65685 6 6 6H5.33333C4.59695 6 4 5.40305 4 4.66667C4 4.29848 4.29848 4 4.66667 4L6 4C8.76142 4 11 6.23858 11 9V10.6667C11 11.403 10.403 12 9.66667 12Z"
      fill="currentColor"
    />
  </svg>
)

export const darkLogo = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.4 2C7.17909 2 7 1.82091 7 1.6C7 0.716344 7.71634 0 8.6 0H9C10.1046 0 11 0.895431 11 2V4.19098C11 4.56267 10.6088 4.80442 10.2764 4.6382L10.1056 4.55279C9.428 4.214 9 3.52148 9 2.76393V2H7.4ZM7.6 10C7.82091 10 8 9.82091 8 9.6C8 8.71634 7.28366 8 6.4 8H6C4.34315 8 3 6.65685 3 5V2.4C3 2.17909 2.82091 2 2.6 2C1.71634 2 1 2.71634 1 3.6V5C1 7.76142 3.23858 10 6 10H7.6Z"
      fill="#DEF1FF"
    />
    <path
      d="M3 11.5C3 11.7761 2.77614 12 2.5 12H2C1.44772 12 1 11.5523 1 11L1 9.80902C1 9.43733 1.39116 9.19558 1.72361 9.3618L1.89443 9.44721C2.572 9.786 3 10.4785 3 11.2361V11.5Z"
      fill="#58B9FF"
    />
    <path
      d="M9.66667 12C9.29848 12 9 11.7015 9 11.3333V9C9 7.34315 7.65685 6 6 6H5.33333C4.59695 6 4 5.40305 4 4.66667C4 4.29848 4.29848 4 4.66667 4L6 4C8.76142 4 11 6.23858 11 9V10.6667C11 11.403 10.403 12 9.66667 12Z"
      fill="#58B9FF"
    />
  </svg>
)

export const lightLogo = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.4 2C7.17909 2 7 1.82091 7 1.6C7 0.716344 7.71634 0 8.6 0H9C10.1046 0 11 0.895431 11 2V4.19098C11 4.56267 10.6088 4.80442 10.2764 4.6382L10.1056 4.55279C9.428 4.214 9 3.52148 9 2.76393V2H7.4ZM7.6 10C7.82091 10 8 9.82091 8 9.6C8 8.71634 7.28366 8 6.4 8H6C4.34315 8 3 6.65685 3 5V2.4C3 2.17909 2.82091 2 2.6 2C1.71634 2 1 2.71634 1 3.6V5C1 7.76142 3.23858 10 6 10H7.6Z"
      fill="#324756"
    />
    <path
      d="M3 11.5C3 11.7761 2.77614 12 2.5 12H2C1.44772 12 1 11.5523 1 11L1 9.80902C1 9.43733 1.39116 9.19558 1.72361 9.3618L1.89443 9.44721C2.572 9.786 3 10.4785 3 11.2361V11.5Z"
      fill="#3498E1"
    />
    <path
      d="M9.66667 12C9.29848 12 9 11.7015 9 11.3333V9C9 7.34315 7.65685 6 6 6H5.33333C4.59695 6 4 5.40305 4 4.66667C4 4.29848 4.29848 4 4.66667 4L6 4C8.76142 4 11 6.23858 11 9V10.6667C11 11.403 10.403 12 9.66667 12Z"
      fill="#3498E1"
    />
  </svg>
)

export const arefyLogo = (
  <img src="https://arefy.net/fotos/logo-nuevo.png" width="55" height="29"></img>
)

